import './App.css';
import Hero from './components/Test';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Hero/>    
      </header>
    </div>
  );
}

export default App;
