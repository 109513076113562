import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FaTelegram } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { useMediaQuery } from '@mui/material';
import vtlkImg from '../assets/newresm.png';
import img3 from "../assets/phantom.png";
import sols from "../assets/solscan.png";

const Typewriter = ({ text }) => {
    const [displayText, setDisplayText] = useState("");
    const [index, setIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const theme = useTheme();
    const isBelowLarge = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        const timer = setTimeout(() => {
            const currentChar = text.charAt(index);

            if (isDeleting) {
                setDisplayText(text.substring(0, index - 1));
                setIndex(index - 1);
            } else {
                setDisplayText(text.substring(0, index) + "|"); // | işareti ekleniyor
                setIndex(index + 1);
            }

            if (!isDeleting && index === text.length) {
                setIsDeleting(true);
            } else if (isDeleting && index === 0) {
                setIsDeleting(false);
            }
        }, 80); // Her karakter arası gecikme süresi (milisaniye cinsinden)

        return () => clearTimeout(timer);
    }, [index, isDeleting, text]);

    const gradientColor = {
        background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    };

    return <Typography style={gradientColor} fontWeight={"bold"} variant={isBelowLarge ? "h5" : "h4"}>{displayText}</Typography>;
};

const Hero = () => {
    const theme = useTheme();
    const isBelowLarge = useMediaQuery(theme.breakpoints.down('lg'));

    const gradientColor = {
        background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Box>
                <img style={{ maxWidth: isBelowLarge ? '250px' : '500px', maxHeight: isBelowLarge ? '250px' : '500px', border: "3px solid #000000", borderRadius: 50 }} src={vtlkImg} alt="vtlk" />
                <Typewriter text="VITALIJEET PROJECT" />
                <Box sx={{ maxWidth: isBelowLarge ? '250px' : '500px', width: '100%' }}>
                    <Typography fontWeight={"bold"} textAlign={"center"} variant={isBelowLarge ? "h5" : "h4"}>
                    VITALIJEET IS HERE FOR ONE REASON, TO DESTROY AND GAIN TRUST AMONG INVESTORS AGAIN!                    
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton href='https://t.me/VitalJeetBuyterChjiken'> <FaTelegram style={{ color: 'white' }} fontSize={isBelowLarge ? "40px" : "50px"} /> </IconButton>
                <IconButton href='https://twitter.com/JeetVital'> <FaSquareXTwitter style={{ color: 'white' }} fontSize={isBelowLarge ? "40px" : "50px"} /> </IconButton>
                <IconButton href='https://youtube.com'> <img style={{width:isBelowLarge ? "45px" : "55px",height:isBelowLarge ? "45px" : "55px"}} src={sols}/> </IconButton>
                <IconButton href='https://youtube.com'> <img style={{width:isBelowLarge ? "45px" : "55px",height:isBelowLarge ? "45px" : "55px"}} src={img3}/> </IconButton>
            </Box>
        </Box>
    );
};

export default Hero;
